import "./Crt.css";
const Crt = ({ isOn, children }) => (
  <div className="h-full w-full">
    <input type="checkbox" id="switch" checked={isOn} />
    <div className="crtContainer h-full w-full">
      <div className="screen w-full h-full">{children}</div>
      <div className="overlay">AV-1</div>
    </div>
  </div>
);

export default Crt;
