import PropTypes from "prop-types";
import YouTube from "react-youtube";
import ReactPlayer from "react-player/lazy";

import { useState } from "react";

const YoutueIframe = ({ id, zoom, playing }) => {
  let style = {};
  if (zoom) {
    style = {
      zoom: zoom,
      MozTransform: `scale(${zoom})`,
      MozTransformOrigin: "0 0",
      OTransform: `scale(${zoom})`,
      OTransformOrigin: "0 0",
      WebkitTransform: `scale(${zoom})`,
      WebkitTransformOrigin: "center",
    };
  }

  return (
    <ReactPlayer
      url={"https://www.youtube.com/watch?v=" + id}
      playing={playing}
      loop={true}
      muted={!playing}
      style={style}
      width="100%"
      height="100%"
    />
  );

  // const handlePlay = (ev) => {
  //   ev.target.unMute();
  //   ev.target.playVideo();
  // };

  // return (
  //   <YouTube
  //     videoId={id}
  //     className="h-full w-full"
  //     opts={{
  //       playerVars: {
  //         autoplay: 1,
  //         modestbranding: 1,
  //         showinfo: 0,
  //         // controls: 0,
  //         loop: 1,
  //         mute: 1,
  //       },
  //     }}
  //     onPlay={handlePlay}
  //   />
  // );
  // return (
  //   <iframe
  //     title="video"
  //     className="w-full h-full"
  //     ref={(ref) => console.log({ ref, crr: ref.current })}
  //     style={style}
  //     src={`https://www.youtube.com/embed/${id}?autoplay=1&mute=0&modestbranding=1&autohide=1&showinfo=0&controls=1&loop=1&playlist=${id}`}
  //     allow="autoplay"
  //   />
  // );
};

YoutueIframe.propTypes = {
  id: PropTypes.string.isRequired,
};

YoutueIframe.defaultProps = {};

export default YoutueIframe;
