import { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const Spinner = () => (
  <svg
    className="mx-auto animate-spin h-5 w-5 text-red- hover:text-white"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx={12}
      cy={12}
      r={10}
      stroke="currentColor"
      strokeWidth={4}
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

const SignupForm = ({ className }) => {
  const [email, setEmail] = useState(null);

  const handleSubscribe = ({ subscribe }) => {
    if (!validateEmail(email)) {
      alert("The email is not valid, check and try again.");
      return;
    }

    subscribe({ EMAIL: email });
  };

  const buttonIsDisabled = ({ status }) => {
    return status === "sending" || status === "success";
  };

  return (
    <MailchimpSubscribe
      url="https://gmail.us1.list-manage.com/subscribe/post?u=92816098864cea6d11cf8ab17&amp;id=cd27265017"
      render={({ subscribe, status, message }) => (
        <div className={className}>
            <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-2 text-computer w-full lg:px-3 px-12">
          {status === "success" ? (
            "Congrats, you are officially Retromoderna :)"
          ) : (
            <>
              <input
                className="flex-shrink rounded-lg lg:rounded-r-none p-4  
                text-gray-800 border-gray-200 bg-white shadow-xl min-w-0
                border-t border-b border-l lg:border-r-0 border-r"
                placeholder="me@mail.com"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
                />

              <button
                className="flex-grow rounded-lg lg:rounded-l-none p-4 uppercase transition 
                bg-red-200 text-red-600 hover:bg-red-600  hover:text-white 
                text-sm text-center justify-center min-w-max"
                onClick={() => 
                  handleSubscribe({ subscribe })
                }
                disabled={buttonIsDisabled({ status })}
                >
                {status === "sending" ? (
                  <div className="flex flex-row space-x-2">
                    <Spinner />
                    <p> Loading..</p>
                  </div>
                ) : (
                  "Subscribe!"
                  )}
              </button>
              </>
          )}
            </div>
        </div>
      )}
    />
  );
};

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default SignupForm;
