import { TvWrapper, YoutubeIframe, SignupForm } from "./components";
import { useState } from "react";
import logo from "./assets/img/newLogo.png";
import "./App.css";

const Marquee = ({ invertDirection, text }) => {
  return (
    <div
      className="absolute bottom-8 right-0 h-full w-full bg-red-600 transform invisible md:visible"
      style={{ height: 35 }}
    >
      <marquee
        direction={invertDirection ? "right" : "left"}
        className="text-computer align-middle text-white"
      >
        <p>{text}</p>
      </marquee>
    </div>
  );
};

const Logo = ({ className }) => {
  return <img alt="Retromodernas Logo" className={className} src={logo} />;
};

function App() {
  const [tvIsOn, setTvIsOn] = useState(false);

  return (
    <div className="h-screen w-screen">
      <div className="grid lg:grid-cols-3 lg:grid-rows-3 grid-rows-4 container h-full w-full m-auto">
        <Logo className="mx-auto mt-0 p-0 justify-self-end place-self-center" />
        <TvWrapper
          className="lg:col-span-2 lg:row-span-3 lg:row-start-1 row-start-2 row-span-2"
          tvIsOn={tvIsOn}
          onTvClick={() => setTvIsOn((isOn) => !isOn)}
        >
          <YoutubeIframe
            id="tjM36Tr4wqw"
            startAt={9}
            zoom={1.8}
            playing={tvIsOn}
          />
        </TvWrapper>
        <SignupForm className="w-full" />
      </div>

      <Marquee text="RETROMODERNAS      -     CLICK ON THE TV TO START PLAYING    -     RETROMODERNAS" />
    </div>
  );
}

export default App;
