import tvImage from "../assets/img/retrotv.png";
import handClickImg from "../assets/img/handClick.gif";

import { useState, useRef, useEffect } from "react";
import Crt from "./Crt";

const TvWrapper = ({ children, className, tvIsOn, onTvClick }) => {
  const tvImgRef = useRef(null);
  const containerRef = useRef(null);

  const [tvTopOffset, setTvTopOffset] = useState(-1);
  const [tvBottomOffset, setTvBottomOffset] = useState(-1);

  const [tvLeftOffset, setTvLeftOffset] = useState(-1);
  const [tvRightOffset, setTvRightOffset] = useState(-1);

  const [tvWidth, setTvWidth] = useState(-1);
  const [tvHeight, setTvHeight] = useState(-1);

  const [tvLeftMargin, setTvLeftMargin] = useState(0);
  const [tvTopMargin, setTvTopMargin] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", setImageDimensions);

    return () => window.removeEventListener("resize", setImageDimensions);
  }, []);

  const orignalImageWidth = 900;
  const originalImageHeight = 620;

  const leftOffsetFactor = 70 / orignalImageWidth;
  const rightOffsetFactor = 240 / orignalImageWidth;

  const topOffsetFactor = 65 / originalImageHeight;
  const bottomOffsetFactor = 23 / originalImageHeight;

  const setImageDimensions = () => {
    const img = tvImgRef?.current;

    if (img === null || img === undefined) {
      return;
    }

    setTvWidth(img.offsetWidth);
    setTvHeight(img.offsetHeight);

    setTvLeftMargin(img.offsetLeft);
    setTvTopMargin(img.offsetTop);
  };

  useEffect(() => {
    setTvLeftOffset(leftOffsetFactor * tvWidth);
    setTvRightOffset(rightOffsetFactor * tvWidth);
  }, [tvWidth]);

  useEffect(() => {
    setTvTopOffset(topOffsetFactor * tvHeight);
    setTvBottomOffset(bottomOffsetFactor * tvHeight);
  }, [tvHeight]);

  console.log({
    tvHeight,
    tvWidth,
    tvLeftMargin,
    tvTopMargin,
    tvLeftOffset,
    tvTopOffset,
  });
  return (
    <div ref={containerRef} className={"relative " + className}>
      <div
        className="absolute top-0 left-0 z-0"
        style={{
          paddingTop: tvTopOffset + tvTopMargin,
          paddingLeft: tvLeftOffset + tvLeftMargin,
          width: tvWidth - tvRightOffset + tvLeftMargin,
          height: tvHeight - tvTopOffset - tvBottomOffset,
        }}
      >
        <Crt isOn={tvIsOn}>{children}</Crt>
      </div>
      {tvIsOn || (
        <img
          src={handClickImg}
          className={"absolute z-50 lg:h-36 h-16 w-auto"}
          style={{
            top: tvHeight * (2 / 3) + tvTopMargin,
            left: tvWidth * (4 / 6) + tvLeftMargin,
          }}
        />
      )}
      <img
        ref={tvImgRef}
        className="relative cursor-pointer max-h-full max-w-full h-auto w-auto mx-auto"
        id="RetroTv"
        alt="Old TV - Retromodernas video introduction container"
        src={tvImage}
        onLoad={(ev) => setImageDimensions(ev.target)}
        onClick={() => onTvClick()}
      />
    </div>
  );
};

export default TvWrapper;
